import { serializeArray } from '../components/utilities';

const Webforms = {
  submitWebForm: async function (form, email) {
    const textBtnDefault = form.querySelector('.action').innerHTML;
    const labelInput = form.querySelector('label.file');
    const formData = new FormData(form);
    const file = document.getElementById('file');

    formData.append('resume', file.files[0]);

    if (typeof email == 'undefined' || email == '') {
      email = form.querySelector('[name="email"]').value;
    }

    form.querySelector('[name="reply_to"]').value = email;

    if (!form.classList.contains('sending')) {
      form.classList.add('sending');
      form.classList.remove('error');
      form.querySelector('.action').innerHTML = 'Enviando';

      try {
        const response = await fetch('/webform', {
          method: 'POST',
          headers: {},
          body: formData,
        });

        if (response.ok) {
          console.info('Formulário enviado com sucesso');
          form.classList.add('sent');
          form.querySelector('.msg-success').classList.add('-visible');
          form.querySelector('.action').innerHTML = 'Enviado';
          form.reset();

          setTimeout(function () {
            form.classList.remove('sent');
            form.querySelector('.msg-success').classList.remove('-visible');
            form.querySelector('.action').innerHTML = textBtnDefault;
            if (labelInput) {
              labelInput.textContent = ' * Anexar currículo (doc, docx, pdf)';
            }
          }, 5000);
        } else {
          const text = await response.text();

          console.error(
            `Ocorreu um erro no envio do formulário. Erro ${response.status}`
          );
          console.error(text);

          form.classList.add('error');
          form.querySelector('.msg-error').classList.add('-visible');
          form.querySelector('.action').innerHTML = 'Falha ao enviar';

          setTimeout(function () {
            form.classList.remove('error');
            form.querySelector('.msg-error').classList.remove('-visible');
            form.querySelector('.action').innerHTML = textBtnDefault;
            if (labelInput) {
              labelInput.textContent = ' * Anexar currículo (doc, docx, pdf)';
            }
          }, 5000);
        }
      } catch (error) {
        console.error('Erro ao enviar o webform');
        console.error(error);

        form.classList.add('error');
        form.querySelector('.msg-error').classList.add('-visible');
        form.querySelector('.action').innerHTML = 'Falha ao enviar';

        setTimeout(function () {
          form.classList.remove('error');
          form.querySelector('.msg-error').classList.remove('-visible');
          form.querySelector('.action').innerHTML = textBtnDefault;
          if (labelInput) {
            labelInput.textContent = ' * Anexar currículo (doc, docx, pdf)';
          }
        }, 5000);
      }

      form.classList.remove('sending');
    }
  },
  submitNotify: async function (form) {
    const formData = serializeArray(form);
    const btnTextDefault = form.querySelector('.action').innerHTML;

    if (!form.classList.contains('sending')) {
      form.classList.remove('error');
      form.classList.add('sending');
      form.querySelector('.action').innerHTML = 'Enviando';

      try {
        const response = await fetch('/lista_de_espera', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
          },
          body: formData,
        });

        if (response.ok) {
          form.classList.remove('sending');

          // Envia um webform de aviso de cadastro
          console.log('Lista de espera enviada com sucesso');
          Webforms.submitWebForm(form);
        } else {
          const text = await response.text();
          console.error(
            `Ocorreu um erro ao enviar o formulário de Lista de Espera. Erro ${response.status}`
          );
          console.error(text);

          form.classList.add('error');
          form.querySelector('.msg-error').classList.add('-visible');
          form.querySelector('.action').innerHTML = 'Falha ao enviar';

          setTimeout(() => {
            form.classList.remove('error');
            form.querySelector('.msg-error').classList.remove('-visible');
            form.querySelector('.action').innerHTML = btnTextDefault;
          }, 3500);
        }
      } catch (error) {
        console.error('Falha ao enviar lista de espera. Verificar integração.');
        console.error(error);

        form.classList.add('error');
        form.querySelector('.msg-error').classList.add('-visible');
        form.querySelector('.action').innerHTML = 'Falha ao enviar';

        setTimeout(() => {
          form.classList.remove('error');
          form.querySelector('.msg-error').classList.remove('-visible');
          form.querySelector('.action').innerHTML = btnTextDefault;
        }, 3500);
      }

      form.classList.remove('sending');
    }
  },
  showMessage: function (input, form) {
    const alertMessage = form.querySelector('[data-msg]');
    const label = form.querySelector('.input.file');

    alertMessage.classList.add('-visible');
    alertMessage.innerText =
      'Um ou mais campos não foram preenchidos corretamente. \n Verifique e tente novamente.';

    input.classList.add('-emphasis');

    setTimeout(() => {
      alertMessage.classList.remove('-visible');
      alertMessage.innerText = '';

      input.classList.remove('-emphasis');

      if (label) {
        label.classList.remove('-emphasis');
      }
    }, 5000);
  },
  showMessageFile: function (input, form) {
    const alertMessage = form.querySelector('[data-msg]');
    const label = form.querySelector('.input.file');

    alertMessage.classList.add('-visible');
    alertMessage.innerText =
      'Arquivo grande demais, por favor selecione um arquivom com menos de 4MB';

    input.classList.add('-emphasis');

    setTimeout(() => {
      alertMessage.classList.remove('-visible');
      alertMessage.innerText = '';

      input.classList.remove('-emphasis');

      if (label) {
        label.classList.remove('-emphasis');
      }
    }, 5000);
  },
  validateForm: function (vndaInput, form) {
    let submitAllowed = true;

    // Permite o envio se o input vnda estiver vazio
    if (vndaInput.value == '') {
      vndaInput.setAttribute('required', false);

      // Valida se os campos obrigatórios estão preenchidos
      const requiredFields = form.querySelectorAll(
        '[required]:not([required=false])'
      );

      requiredFields.forEach((input) => {
        if (input.value == '') {
          submitAllowed = false;
          Webforms.showMessage(input, form);
        }

        if (input.type == 'file') {
          const label = form.querySelector('.input.file');
          const hasFile = input.files[0];

          if (input.value == '') {
            submitAllowed = false;
            Webforms.showMessage(label, form);
          }

          if (hasFile) {
            const fsize = hasFile.size;
            const file = Math.round(fsize / 1024);

            if (file >= 4096) {
              submitAllowed = false;
              Webforms.showMessageFile(label, form);
            }
          }
        }

        if (input.type == 'select-one') {
          const isValid = input.checkValidity();

          if (!isValid) {
            submitAllowed = false;
            Webforms.showMessage(input, form);
          }
        }

        if (input.type == 'checkbox' && input.checked == false) {
          submitAllowed = false;
          Webforms.showMessage(input, form);
        }
      });

      const radio = form.querySelector('input[type=radio][required]');

      if (radio) {
        submitAllowed = Webforms.validateRadio(form);
      }

      if (submitAllowed)
        form.id == 'form-notify'
          ? Webforms.submitNotify(form)
          : Webforms.submitWebForm(form);
    }
  },
  validateRadio: function (form) {
    const radio = form.querySelectorAll('input[type=radio][required]');
    let valueRadio = '';
    let radioGroup;
    let submitAllowed = true;

    for (let i = 0; i < radio.length; i++) {
      const input = radio[i];
      radioGroup = input.closest('.form-radio');

      if (input.checked) {
        valueRadio = input.value;
        break;
      }
    }

    if (valueRadio == '') {
      submitAllowed = false;
      Webforms.showMessage(radioGroup, form);
    } else {
      submitAllowed = true;
    }

    return submitAllowed;
  },
  handleFileInput: function () {
    const fileInput = document.querySelector('input#file');

    if (fileInput) {
      const labelInput = document.querySelector('label.file');

      fileInput.addEventListener('change', function () {
        const hasFile = fileInput.files[0];

        if (typeof hasFile != 'undefined') {
          labelInput.textContent = hasFile.name;
        } else {
          labelInput.textContent = ' * Anexar currículo (doc, docx, pdf)';
        }
      });
    }
  },
  loadUF: async function () {
    const urlUF = 'https://servicodados.ibge.gov.br/api/v1/localidades/estados';
    const stateInput = document.getElementById('state');
    const cityInput = document.getElementById('city');
    const state_text = 'Estado';

    if (languagejs === 'en') {
      state_text = 'State';
    }

    if (stateInput) {
      try {
        const request = await fetch(urlUF);
        const response = await request.json();

        stateInput.innerHTML = `<option value=""> ${state_text}</option>`;

        response.forEach((uf) => {
          const option = document.createElement('option');

          option.setAttribute('value', uf.sigla);
          option.innerText += `${uf.sigla}`;

          stateInput.append(option);
        });
      } catch (error) {
        console.error('Erro ao buscar estados');
        console.error(error);
      }

      if (cityInput) Webforms.loadCities();
    }
  },
  loadCities: async function () {
    const stateInput = document.getElementById('state');
    const cityInput = document.getElementById('city');

    stateInput.addEventListener('change', async function () {
      const urlCities = `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${stateInput.value}/municipios`;

      cityInput.innerHTML = '<option value=""> Cidade</option>';

      try {
        const request = await fetch(urlCities);
        const response = await request.json();

        response.forEach((city) => {
          const option = document.createElement('option');

          option.setAttribute('value', city.nome);
          option.innerText += `${city.nome}`;

          cityInput.append(option);
        });
      } catch (error) {
        console.error('Erro ao buscar estados');
        console.error(error);
      }
    });
  },
  setWebForms: function () {
    const webForms = document.querySelectorAll('[data-webform]');

    webForms.length > 0 &&
      webForms.forEach((form) => {
        const button = form.querySelector('button');
        const fieldset = form.querySelector('fieldset');

        Webforms.handleFileInput();
        Webforms.loadUF();

        // Desabilita os campos do formulário, através do fieldset, quando o input vnda recebe algum valor
        let vndaInput = form.querySelector('input[name="vnda"]');
        vndaInput.addEventListener('input', () =>
          fieldset.setAttribute('disabled', true)
        );

        // Realiza o envio através do clique e da tecla enter sobre o botão do formulário
        button &&
          button.addEventListener('click', () =>
            Webforms.validateForm(vndaInput, form)
          );
        button &&
          button.addEventListener('keypress', (e) => {
            if (e.key === 'Enter') Webforms.validateForm(vndaInput, form);
          });
      });
  },
  init: function () {
    const _this = this;
    _this.setWebForms();
  },
};

export default Webforms;
